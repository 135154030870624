import { animated, useTransition } from '@react-spring/web';
import { ReactNode } from 'react';

export default function ScreenOverlay({
  open = true,
  cbFunc,
  display = 'flex items-end justify-center',
  position = 'fixed top-0 left-0',
  height = 'h-screen',
  width = 'w-screen',
  background = 'bg-black bg-opacity-50',
  children,
  addClassStyles = '',
  zIndex = 'z-40',
}: {
  open: boolean;
  cbFunc: any;
  display?: string;
  position?: string;
  height?: string;
  width?: string;
  background?: string;
  children: ReactNode;
  addClassStyles?: string;
  zIndex?: string;
}) {
  const transition = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return transition(
    (props, item) =>
      item && (
        <animated.div
          style={props}
          onClick={cbFunc}
          className={`${zIndex} ${background} ${height} ${width} ${position} ${display} ${addClassStyles}`}
        >
          {children}
        </animated.div>
      )
  );
}
