import { ReactNode } from 'react';

type PageLayoutProps = {
  margin?: string;
  width?: string;
  padding?: string;
  addClassStyles?: string;
  navBar?: JSX.Element;
  footer?: JSX.Element;
  children: ReactNode;
};

export default function PageLayout({
  margin = 'mx-auto',
  width = '',
  padding = '',
  addClassStyles = '',
  children,
}: PageLayoutProps) {
  return (
    <div className={`${margin} ${width} ${padding} ${addClassStyles}`}>
      {children}
    </div>
  );
}
