export const reactQueryKeys = {
  SSF_PROVIDER: 'SSF_PROVIDER',
  LOGGED_IN_USER: 'LOGGED_IN_USER',
  LOGGED_IN_USER_INFO: 'LOGGED_IN_USER_INFO',
  USER_VARIANT_WATCHLIST: 'USER_VARIANT_WATCHLIST',
  USER_GET_DISCOVERY_INTERACTION_VARIANT:
    'user_get_discovery_interaction_variant',
  USER_DISCOVERY_INTERACTIONS: 'USER_DISCOVERY_INTERACTIONS',
  USER_VARIANT_REQUESTS: 'USER_VARIANT_REQUESTS',
  USER_PROFILE_COLLECTION_PIECES: 'USER_PROFILE_COLLECTION_PIECES',
  USER_COLLECTION_PIECES: 'USER_COLLECTION_PIECES',
  USER_CP_AND_VR_FOR_ONE_ARTIST: 'USER_CP_AND_VR_FOR_ONE_ARTIST',
  USER_COLLECTION_PIECE_TOTALS: 'USER_COLLECTION_PIECE_TOTALS',
  USER_VARIANT_REQUEST_TOTALS: 'USER_VARIANT_REQUEST_TOTALS',
  USER_DISTINCT_VARIANT_REQUEST_ARTIST_NAMES:
    'user_distinct_variant_request_artist_names',
  USER_DISTINCT_VARIANT_REQUEST_ARTIST_NAMES_IN_USER_PROFILE:
    'user_distinct_variant_request_artist_names_in_user_profile',
  USER_DISTINCT_COLLECTION_ARTISTS: 'USER_DISTINCT_COLLECTION_ARTISTS',
  USER_DISTINCT_PROFILE_ARTISTS: 'USER_DISTINCT_PROFILE_ARTISTS',
  USER_COLLECTION_PIECES_COUNT: 'USER_COLLECTION_PIECES_COUNT',
  ADMIN_GET_COLLECTION_PIECES: 'ADMIN_GET_COLLECTION_PIECES',
  ADMIN_GET_COLLECTION_PIECE_TOTALS: 'ADMIN_GET_COLLECTION_PIECE_TOTALS',
  USER_COLLECTION_PIECES_COUNT_IN_PROFILE:
    'user_collection_pieces_count_in_profile',
  USER_VARIANT_REQUESTS_COUNT: 'USER_VARIANT_REQUESTS_COUNT',
  FIND_MY_USER_MARKET_PLACE: 'FIND_MY_USER_MARKET_PLACE',
  COUNT_MY_MARKET_PLACE_LISTINGS: 'COUNT_MY_MARKET_PLACE_LISTINGS',
  SUM_MY_MARKET_PLACE_LISTINGS: 'SUM_MY_MARKET_PLACE_LISTINGS',
  FIND_MY_MARKET_PLACE_LISTINGS: 'FIND_MY_MARKET_PLACE_LISTINGS',
  FIND_MY_MARKET_PLACE_ORDERS_AS_SELLER:
    'FIND_MY_MARKET_PLACE_ORDERS_AS_SELLER',
  USER_PROFILE_VARIANT_REQUESTS_COUNT: 'USER_PROFILE_VARIANT_REQUESTS_COUNT',
  GET_ARTISTS: 'GET_ARTISTS',
  GET_ARTISTS_WITH_MPL: 'GET_ARTISTS_WITH_MPL',
  GET_ARTIST_BY_ID: 'GET_ARTIST_BY_ID',
  GET_VARIANT_BY_HANDLE: 'GET_VARIANT_BY_HANDLE',
  GET_VARIANT_BY_ID: 'GET_VARIANT_BY_ID',
  GET_USER_ACTIVE_COLLECTOR_LISTS: 'GET_USER_ACTIVE_COLLECTOR_LISTS',
  GET_VARIANTS: 'GET_VARIANTS',
  GET_MARKET_PLACE_LISTINGS: 'GET_MARKET_PLACE_LISTINGS',
  GET_VARIANTS_WITH_SHOPIFY_OBJECT: 'GET_VARIANTS_WITH_SHOPIFY_OBJECT',
  GET_VARIANTS_COUNT: 'GET_VARIANTS_COUNT',
  GET_SHOWS: 'GET_SHOWS',
  GET_SHOWS_COUNT: 'GET_SHOWS_COUNT',
  MY_ARTIST_OBJECT: 'MY_ARTIST_OBJECT',
  ARTIST_WAITLIST_USER: 'ARTIST_WAITLIST_USER_COLLECTION_COUNT',
  ADMIN_GET_USER_PROFILES: 'ADMIN_GET_USER_PROFILES',
};
