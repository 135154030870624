export default function RightCheveron({
  stroke = 'currentColor',
  large = false,
  hover = 'transform group-hover:translate-x-0.8 ',
  transition = 'transition-all duration-300 ease-out',
}: {
  stroke?: string;
  large?: boolean;
  hover?: string;
  transition?: string;
}) {
  return (
    <div className={`${transition} ${hover}`}>
      {large ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6L15 12L9 18"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4L10 8L6 12"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
}
