export default function CloseIcon({
  onClick,
  fill = '#1A1A1A',
  large = false,
}: {
  onClick?: () => any;
  fill?: string;
  large?: boolean;
}) {
  return (
    <>
      {large ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className="cursor-pointer"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.71582 6.28941C7.32529 5.89888 6.68617 5.90484 6.29564 6.29536C5.90512 6.68589 5.89916 7.32501 6.28969 7.71553L10.574 11.9999L6.2897 16.2842C5.89918 16.6747 5.90513 17.3139 6.29566 17.7044C6.68618 18.0949 7.3253 18.1009 7.71583 17.7103L12.0002 13.426L16.2845 17.7103C16.675 18.1009 17.3141 18.0949 17.7047 17.7044C18.0952 17.3139 18.1011 16.6747 17.7106 16.2842L13.4263 11.9999L17.7106 7.71553C18.1012 7.32501 18.0952 6.68589 17.7047 6.29537C17.3142 5.90484 16.675 5.89888 16.2845 6.28941L12.0002 10.5738L7.71582 6.28941Z"
            fill={fill}
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
          className="cursor-pointer"
        >
          <g clipPath="url(#clip0_131_297)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2426 3.75736C11.8521 3.36683 11.219 3.36683 10.8284 3.75736L8 6.58578L5.17157 3.75736C4.78105 3.36683 4.14788 3.36683 3.75736 3.75736C3.36684 4.14788 3.36684 4.78105 3.75736 5.17157L6.58579 8L3.75736 10.8284C3.36684 11.2189 3.36684 11.8521 3.75736 12.2426C4.14788 12.6332 4.78105 12.6332 5.17157 12.2426L8 9.41421L10.8284 12.2426C11.219 12.6332 11.8521 12.6332 12.2426 12.2426C12.6332 11.8521 12.6332 11.219 12.2426 10.8284L9.41421 8L12.2426 5.17157C12.6332 4.78105 12.6332 4.14788 12.2426 3.75736Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_131_297">
              <rect width="16" height="16" fill={fill} />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
}
