import { RefObject, useCallback, useEffect, useRef } from 'react';

export default function useIntersectionObserverRef(
  loading: boolean,
  cbFn: () => any
) {
  const observer = useRef<any>();
  return useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          cbFn();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, cbFn]
  );
}
export function useIntersectionObserverWithRef(
  ref: RefObject<HTMLElement>,
  cbFn: () => any
) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  // const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) cbFn();
    });
  }, [cbFn]);

  useEffect(() => {
    if (!observerRef.current || !ref.current) return;

    observerRef.current.observe(ref.current);

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [ref]);
}
