import Link from 'next/link';

type CtaLinkProps = {
  newTab?: boolean;
  href: string;
  margin?: string;
  padding?: string;
  height?: string;
  width?: string;
  color?: string;
  background?: string;
  display?: string;
  outline?: string;
  border?: string;
  font?: string;
  children?: React.ReactNode;
};

export default function CtaLink({
  href,
  newTab,
  margin = '',
  padding = '',
  height = 'h-6.4',
  width = 'w-full max-w-xl',
  color = 'text-grey-100',
  background = 'bg-black-100',
  display = 'flex justify-center items-center',
  outline = '',
  border = '',
  font = 'text-s font-medium',
  children,
}: CtaLinkProps) {
  return (
    (<Link
      href={href}
      target={newTab ? '_blank' : '_self'}
      className={`group ${height} ${width} ${margin} ${padding} ${color} ${background} ${outline} ${display} ${border} ${font}`}>

      {children}

    </Link>)
  );
}

export const SecondaryCtaLink = (props: CtaLinkProps) =>
  CtaLink({
    ...props,
    color: 'text-black-100',
    background: 'bg-grey-100',
  });

export const TextCtaLink = (props: CtaLinkProps) =>
  CtaLink({
    ...props,
    background: '',
    height: '',
    width: '',
  });
