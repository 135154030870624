import { verifyUser } from '@/utils/v2HttpRequests';
import { reactQueryKeys } from '@/utils/reactQueryKeys';
import Cookies from 'js-cookie';
import { User } from 'lib/interfaces';
import { useQuery, UseQueryResult } from 'react-query';

export default function useUserV2(): UseQueryResult<User, Error> {
  return useQuery<User, Error>(
    reactQueryKeys.LOGGED_IN_USER,
    () => verifyUser(),
    {
      enabled: !!Cookies.get('token'),
      refetchOnWindowFocus: false,
      onError: (_error) => Cookies.remove('token'),
    }
  );
}
