export default function MagnifyingGlass({
  fill = 'currentColor',
}: {
  fill?: string;
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 7.06674C13.5 9.69931 11.3659 11.8334 8.73331 11.8334C7.67218 11.8334 6.69204 11.4867 5.9 10.9003L3.27782 13.5225L2.5 12.7447L5.09647 10.1482C4.3917 9.31729 3.96662 8.24165 3.96662 7.06674C3.96662 4.43417 6.10074 2.30005 8.73331 2.30005C11.3659 2.30005 13.5 4.43417 13.5 7.06674ZM12.4 7.06674C12.4 9.09179 10.7584 10.7334 8.73331 10.7334C6.70826 10.7334 5.06663 9.09179 5.06663 7.06674C5.06663 5.04168 6.70826 3.40005 8.73331 3.40005C10.7584 3.40005 12.4 5.04168 12.4 7.06674Z"
        fill={fill}
      />
    </svg>
  );
}
