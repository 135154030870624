import { TwelveColGridProps } from '@/lib/interfaces';

export default function TwelveColGrid({
  mx = 'mx-auto',
  my = '',
  px = 'px-1.6 lgt:px-0',
  py = '',
  gap = 'gap-x-0.8 lgt:gap-x-2.4',
  width = 'max-w-screen-lgt',
  addClassStyles = '',
  children,
}: TwelveColGridProps) {
  return (
    <div
      className={`grid grid-cols-12 ${gap} ${mx} ${my} ${px} ${py} ${width} ${addClassStyles}`}
    >
      {children}
    </div>
  );
}
