import { CtaButtonType } from '@/lib/interfaces';

type CtaButtonProps = {
  onClick?: () => any;
  margin?: string;
  padding?: string;
  type?: CtaButtonType;
  disabled?: boolean;
  height?: string;
  width?: string;
  color?: string;
  background?: string;
  display?: string;
  border?: string;
  borderRadius?: string;
  shadow?: string;
  disabledStyles?: string;
  hover?: string;
  transition?: string;
  font?: string;
  children?: React.ReactNode;
};

export default function CtaButton({
  onClick,
  margin = '',
  padding = '',
  type = 'button',
  disabled = false,
  height = 'h-6.4',
  width = 'w-full max-w-xl',
  color = 'text-grey-100',
  shadow = '',
  background = 'bg-black-100',
  display = 'flex justify-center items-center',
  disabledStyles = 'disabled:text-black-300',
  border = '',
  borderRadius = '',
  font = 'text-s font-medium',
  hover = '',
  transition = 'transition-all',
  children,
}: CtaButtonProps) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`group ${disabledStyles} ${hover} ${transition} ${height} ${width} ${margin} ${padding} ${color} ${background} ${border} ${display} ${borderRadius} ${font} ${shadow} ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
    >
      {children}
    </button>
  );
}

export const SecondaryCtaButton = (props: CtaButtonProps) =>
  CtaButton({
    ...props,
    color: 'text-black-100',
    background: 'bg-grey-100',
  });
