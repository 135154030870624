import {
  FilterOptionV2,
  PopulationOptionV2,
  SortOptionV2,
} from '@/lib/interfaces';
import { reactQueryKeys } from '@/utils/reactQueryKeys';
import { genQs } from '@/utils/v2HelperMethods';
import { debounce } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

type SearchSortAndFilterProviderV2Props = {
  ssfQuery: UseInfiniteQueryResult<any, unknown>;
  isFilterViewOpen: boolean;
  setIsFilterViewOpen: Dispatch<SetStateAction<boolean>>;
  isSearchOpen: boolean;
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  closeSearch: () => void;
  updateSearchText: (value: string) => void;
  activeSort: SortOptionV2;
  setActiveSort: Dispatch<SetStateAction<SortOptionV2>>;
  activeFilters: FilterOptionV2[];
  toggleActiveFilters: (opt: FilterOptionV2) => void;
  clearActiveFiltersAndSort: () => void;
};

const SearchSortAndFilterV2 =
  createContext<SearchSortAndFilterProviderV2Props | null>(null);

export function useSearchSortAndFilterProviderV2() {
  const ctx = useContext(SearchSortAndFilterV2);
  if (!ctx) throw Error('Need to Set Context Value');
  return ctx;
}

const defaultNameSearchFilter = (searchVal: string) => ({
  title: 'SEARCH',
  filters: { name: { $containsi: searchVal } },
});

export default function SearchSortAndFilterProviderV2({
  initialSortOption,
  initialFilters = [],
  initialPopulations = [],
  searchFilter = defaultNameSearchFilter,
  fetchFn,
  children,
}: {
  children: ReactNode;
  initialSortOption: SortOptionV2;
  initialFilters?: FilterOptionV2[];
  searchFilter?: (v: string) => FilterOptionV2;
  initialPopulations?: PopulationOptionV2[];
  fetchFn: (s: string) => any;
}) {
  const [isFilterViewOpen, setIsFilterViewOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activeSort, setActiveSort] = useState<SortOptionV2>(initialSortOption);
  const [activeFilters, setActiveFilters] =
    useState<FilterOptionV2[]>(initialFilters);

  const [activePopulations, _setActivePopulations] =
    useState<PopulationOptionV2[]>(initialPopulations);

  const queryParams = {
    sort: activeSort.sort,
    filters: activeFilters
      .map((v) => v.filters)
      .reduce((acc, cv) => {
        Object.keys(cv).map((v) => {
          if (acc[v]) acc[v] = { ...acc[v], ...cv[v] };
          else acc[v] = cv[v];
        });
        return acc;
      }, {}),
    populate: activePopulations
      .map((v) => v.populate)
      .reduce((acc, cv) => {
        return { ...acc, ...cv };
      }, {}),
  };

  const queryKey = genQs(queryParams);

  const ssfQuery = useInfiniteQuery(
    [reactQueryKeys.SSF_PROVIDER, fetchFn.name + queryKey],
    async (opts) => {
      const { pageParam = 1 } = opts;

      const res = await fetchFn(
        genQs({
          pagination: { page: pageParam },
          ...queryParams,
        })
      );
      return res;
    },
    {
      getNextPageParam: (page) => {
        return page.meta.pagination.pageCount > page.meta.pagination.page
          ? page.meta.pagination.page + 1
          : undefined;
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearch = useCallback(
    debounce((opt: FilterOptionV2) => {
      setActiveFilters([...initialFilters, opt]);
    }, 500),
    []
  );

  const closeSearch = () => {
    setSearchText('');
    setActiveFilters([...initialFilters]);
    setIsSearchOpen(false);
  };

  const updateSearchText = (value: string) => {
    setSearchText(value);
    updateSearch(searchFilter(value));
  };

  const toggleActiveFilters = (opt: FilterOptionV2) => {
    setActiveFilters((prev) => {
      const filterOutValList = prev.filter((v) => v.title !== opt.title);
      if (prev.length !== filterOutValList.length) return filterOutValList;
      else return [...prev, opt];
    });
  };

  const clearActiveFiltersAndSort = () => {
    setActiveFilters(initialFilters);
    setActiveSort(initialSortOption);
  };

  return (
    <SearchSortAndFilterV2.Provider
      value={{
        ssfQuery,
        isFilterViewOpen,
        setIsFilterViewOpen,
        isSearchOpen,
        setIsSearchOpen,
        searchText,
        closeSearch,
        updateSearchText,
        activeSort,
        setActiveSort,
        activeFilters,
        toggleActiveFilters,
        clearActiveFiltersAndSort,
      }}
    >
      {children}
    </SearchSortAndFilterV2.Provider>
  );
}
