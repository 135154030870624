export const MAILTO_EMAIL = 'mailto:info@olea.art';

export const CMS_USER_PROFILES_URL = '/api/user-profiles';
export const CMS_GALLERIES_MY_GALLERY_URL = '/api/galleries/find/me';
export const CMS_ARTISTS_URL = '/api/artists';
export const CMS_PUBLIC_ARTISTS_WITH_MPL_URL =
  '/api/artists/pub/market-place-listings';
export const CMS_ARTISTS_MY_ARTIST_URL = '/api/artists/find/me';
export const CMS_SHOWS_URL = '/api/shows';
export const CMS_DISCOVERY_INTERACTION_URL = '/api/discovery-interactions';
export const CMS_ARTIST_APPLICATIONS_URL = '/api/artist-applications';
export const CMS_HOMEPAGE_CAROUSEL_URL = '/api/home-page-carousel';
export const CMS_SCAN_VARIANT_URL = '/api/variants/scan';
export const CMS_VARIANTS_URL = '/api/variants';
export const CMS_MULTI_SEARCH_URL = '/api/variants/multi-search';
export const CMS_VARIANTS_WITH_SHOPIFY_OBJECT_URL = '/api/variants/shopify';
export const CMS_GALLERIES_URL = '/api/galleries';
export const CMS_EVENT_LEADS_URL = '/api/event-lead-captures';
export const CMS_COLLECTION_PIECES_URL = '/api/collection-pieces';
export const CMS_DISCOVER_CONFIG_URL = '/api/discover-config';
export const CMS_MY_COLLECTORS_URL = '/api/collection-pieces/my-collectors';
export const CMS_USER_MY_ARTIST_WAITLISTS_URL =
  '/api/artist-wait-lists/find/me';
export const CMS_REQUEST_VERIFICATION_URL = '/api/users/verification/request';
export const CMS_VERIFY_USER_PROFILE_URL = '/api/users/profile/enabled';
export const CMS_VERIFY_USER_URL = '/api/users/me';
export const CMS_USER_INFO_URL = '/api/users/info/me';
export const CMS_DISCOVER_URL = '/api/users/info/discover';
export const CMS_VARIANT_REQUEST_URL = '/api/variant-requests';
export const CMS_VARIANTS_WATCHLIST_URL = '/api/variants/watchlist';
export const CMS_USER_WAITLIST_FOR_ARTIST_URL = '/api/artist-wait-lists';
export const CMS_GALLERY_WAITLISTS_USERS_URL = '/api/artist-wait-lists/gallery';
export const CMS_ARITST_WAITLISTS_USERS_URL = '/api/artist-wait-lists/artist';
export const CMS_USER_FIND_VARIANT_REQUESTS_URL = '/api/variant-requests/find';
export const CMS_ARTISTS_WAITLIST_SETTINGS_URL =
  '/api/artist-waitlist-settings';
export const CMS_USER_FIND_COLLECTION_PIECES_URL =
  '/api/collection-pieces/find';
export const CMS_DISCOVERY_INTERACTIONS_RESET_ME_URL =
  '/api/discovery-interactions/reset/me';
export const CMS_GET_DISCOVERY_INTERACTION_VARIANT_V1_URL =
  '/api/discovery-interactions/find-next/v1';
export const CMS_GET_DISCOVERY_INTERACTION_VARIANT_V3_URL =
  '/api/discovery-interactions/find-next/v3';
export const CMS_USER_COLLECTION_PIECES_TOTALS_URL =
  '/api/collection-pieces/find/totals';
export const CMS_USER_VARIANT_REQUEST_TOTALS_URL =
  '/api/variant-requests/find/totals';
export const CMS_USER_PROFILE_DISTINCT_ARTISTS_URL =
  '/api/collection-pieces/profile/find/all-artists';
export const CMS_USER_COLLECTION_PIECES_DISTINCT_ARTISTS_URL =
  '/api/collection-pieces/find/all-artists';
export const CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_URL =
  '/api/collection-pieces/find/artist/cp-and-vr';
export const CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_IN_PROFILE_URL = (id: number) =>
  `/api/collection-pieces/profile/find/artist/cp-and-vr/${id}`;
export const CMS_USER_PROFILE_VARIANT_REQUEST_DISTINCT_ARTISTS_URL =
  '/api/variant-requests/profile/find/all-artists';
export const CMS_USER_VARIANT_REQUEST_DISTINCT_ARTISTS_URL =
  '/api/variant-requests/find/all-artists';
export const CMS_ADMIN_CP_TOTALS_FOR_USER_URL = `/api/collection-pieces/admin/find/totals`;
export const CMS_ADMIN_ARITST_WAITLISTS_USERS_SUM_URL = (artistId: number) =>
  `/api/artist-wait-lists/admin/artist/${artistId}/sum`;
export const CMS_ADMIN_ARITST_WAITLISTS_USERS_COUNT_URL = `/api/artist-wait-lists/admin/count`;
export const CMS_ADMIN_ARITST_WAITLISTS_USERS_URL = `/api/artist-wait-lists`;
export const CMS_TOP_COLLECTED_ARTWORKS_URL =
  '/api/collection-pieces/admin/top/artworks';
export const CMS_TOP_COLLECTED_ARTISTS_URL =
  '/api/collection-pieces/admin/top/artists';
export const CMS_TOP_DISCOVERED_ARTWORKS_URL =
  '/api/discovery-interactions/admin/top/artworks';
export const CMS_TOP_DISCOVERED_ARTISTS_URL =
  '/api/discovery-interactions/admin/top/artists';
export const CMS_ARTIST_COLLECTION_PIECES_URL =
  '/api/collection-pieces/artist/managed';
export const CMS_ARTWORKS_MY_COLLECTORS_OWN_URL =
  '/api/collection-pieces/my-artworks-collectors-own';
export const CMS_COLLECTORS_FOR_SINGLE_ARTWORK_URL = (variantId: number) =>
  `/api/collection-pieces/my-artworks-collectors-own/artworks/${variantId}`;
export const CMS_USER_FIND_DISCOVERY_INTERACTIONS_URL =
  '/api/discovery-interactions/find';
export const CMS_USER_FIND_COLLECTION_PIECES_IN_USER_PROFILE_URL =
  '/api/collection-pieces/profile/find';
export const CMS_USER_FIND_COLLECTION_PIECES_COUNT_IN_USER_PROFILE_URL =
  '/api/collection-pieces/profile/find/count';
export const CMS_USER_FIND_VARIANT_REQUESTS_COUNT_IN_USER_PROFILE_URL =
  '/api/variant-requests/profile/find/count';
export const CMS_USER_FIND_COLLECTION_PIECES_COUNT_URL =
  '/api/collection-pieces/find/count';
export const CMS_USER_PROFILE_VARIANT_REQUESTS_URL =
  '/api/variant-requests/profile/find';
export const CMS_USER_FIND_VARIANT_REQUESTS_COUNT_URL =
  '/api/variant-requests/find/count';
export const CMS_GALLERY_WAITLISTS_USERS_COUNT_URL =
  '/api/artist-wait-lists/gallery/count';
export const CMS_ARITST_WAITLISTS_USERS_COUNT_URL =
  '/api/artist-wait-lists/artist/count';

export const SUM_URL = '/sum';
export const COUNT_URL = '/count';
export const COLLECTOR_URL = '/collector';
export const PIECES_URL = '/pieces';
export const TOTALS_URL = '/totals';
export const NAME_URL = '/name';

export const QUERY_SORT_BY_NAME = '&_sort=name:ASC';
export const QUERY_LIMIT_RESULTS = (num: number | string) => `?_limit=${num}`;
export const QUERY_IS_FEATURED = '?featured=true';
export const QUERY_PREFERENCES_ARTWORKS = '?displayForPreferences=true';
export const QUERY_ACCEPTING_COMMISSIONS = '?acceptingCommissions=true';

export const CLIENT_LOGIN_URL = '/login';
export const CLIENT_USER_MY_ARTIST_WAITLISTS_URL = '/user/waitlists';
export const CLIENT_LOGIN_VIA_EMAIL_URL = '/login/email';
export const CLIENT_SIGNUP_URL = '/signup';
export const CLIENT_SIGNUP_VIA_EMAIL_URL = '/signup/email';
export const CLIENT_FAQ_URL = '/faq';
export const CLIENT_TERMS_AND_CONDITIONS_URL = '/legal/terms-and-conditions';
export const CLIENT_MESSAGES_TERMS_OF_SERVICE_URL =
  '/legal/messages/terms-of-service';
export const CLIENT_MESSAGES_PRIVACY_POLICY_URL =
  '/legal/messages/privacy-policy';
export const CLIENT_PRIVACY_POLICY_URL = '/legal/privacy-policy';
export const CLIENT_ARTIST_APPLICATIONS_URL = '/apply/artists';
export const CLIENT_PASSWORD_RESET_URL = '/password/reset';
export const CLIENT_FORGOT_PASSWORD_URL = '/password/forgot';
export const INSTAGRAM_URL = 'https://www.instagram.com/olea.art/';
export const CLIENT_USER_COLLECTION_URL = '/user/collection';
export const CLIENT_USER_WATCHLISTS_URL = '/user/watchlist';
export const CLIENT_USER_WAITLISTS_URL = '/user/waitlists';
export const CLIENT_USER_MARKET_PLACE_ORDERS_URL = '/user/marketplace/orders';
export const CLIENT_USER_MARKET_PLACE_URL = '/user/marketplace';
export const CLIENT_USER_MARKET_PLACE_ADD_CONFIRM_URL = (mplId: number) =>
  `/user/marketplace/add/confirm/${mplId}`;
export const CLIENT_SCAN_ARTWORK_URL = '/scan/artwork';
export const CLIENT_ARTIST_COMMISIONS_URL = '/user/artist/commissions';
export const CLIENT_EXHIBITION_PORTAL_URL = '/exhibition/portal';
export const CLIENT_ARTIST_COLLECTORS_URL = '/user/artist/collectors';
export const CLIENT_ARTIST_SINGLE_ARTWORK_COLLECTORS_URL = (
  variantId: number
) => `/user/artist/collectors/artworks/${variantId}`;
export const CLIENT_ARTIST_SINGLE_COLLECTOR_URL = (author: number) =>
  `/user/artist/collectors/${author}`;
export const CLIENT_ARTIST_WAITLIST_PAGE_URL = '/user/artist/waitlist';
export const CLIENT_GALERY_WAITLIST_PAGE_URL = '/user/gallery/waitlist';
export const CLIENT_ABOUT_URL = '/about';
export const CLIENT_DISCOVER_URL = '/discover';
export const CLIENT_DISCOVER_TUTORIAL_URL = '/discover/tutorial';
export const CLIENT_DISCOVER_INTERACT_URL = '/discover/interact';
export const CLIENT_DISCOVER_LIKED_URL = '/discover/liked';
export const CLIENT_OLEA_COLLECTION_FEATURED_URL =
  '/collection/artworks/featured';
export const CLIENT_OLEA_COLLECTION_SINGLE_ARTIST_ARTWORKS_URL = (
  artist: string
) => `/collection/artists/${artist}`;
export const CLIENT_OLEA_COLLECTION_ARTISTS_URL = '/collection/artists';
export const CLIENT_OLEA_COLLECTION_ALL_ARTWORKS_URL = '/collection/artworks';
export const CLIENT_OLEA_COLLECTION_URL = '/collection';
export const CLIENT_USER_ACCOUNT_URL = '/user/account';
export const CLIENT_UN_BAILE_PREREGISTRATION_URL = '/register/un-baile';
export const CLIENT_UN_TRAJE_PREREGISTRATION_URL = '/register/un-traje';
export const CLIENT_ALEGRIA_PREREGISTRATION_URL = '/register/alegria';
export const CLIENT_USER_COLLECTION_ADD_URL = '/user/collection/add/artists';
export const CLIENT_USER_COLLECTION_PIECE_EDIT_URL = (
  collectionPieceId: string | number
) => `/user/collection/edit/artwork/${collectionPieceId}`;
export const CLIENT_USER_VARIANT_REQUEST_EDIT_URL = (
  variantRequestId: string | number
) => `/user/collection/edit/request/${variantRequestId}`;
export const CLIENT_USER_COLLECTION_ADD_MANUAL_URL =
  '/user/collection/add/manual';
export const CLIENT_ARTISTS_URL = '/artists';
export const CLIENT_ARTWORKS_URL = '/artworks';
export const CLIENT_ARTWORK_MARKET_PLACE_LISTINGS_URL = (
  variantId: number | string
) => `/artworks/${variantId}/listings`;
export const CLIENT_MARKET_PLACE_LISTINGS_URL = '/listings';
export const CLIENT_SINGLE_MARKET_PLACE_LISTING_URL = (id: number | string) =>
  `/listings/${id}`;
export const CLIENT_SINGLE_ARTWORK_URL = (id: number) => `/artworks/${id}`;
export const CLIENT_INDEX_URL = '/';
export const CLIENT_USER_PROFILE_URL = (url_handle: string) =>
  `/user/${url_handle}/profile`;
export const CLIENT_USER_PUBLIC_MARKETPLACE_URL = (url_handle: string) =>
  `/user/${url_handle}/marketplace`;
export const CLIENT_USER_PROFILE_OWNED_BY_ARTIST_URL = (
  url_handle: string,
  artist_url_handle: string
) => `/user/${url_handle}/profile/owned/${artist_url_handle}`;
export const CLIENT_BASEL_URL = '/basel';
export const CLIENT_ADD_ARTIST_ARTWORK_URL = (id: number) =>
  `/user/collection/add/artists/${id}`;
export const CLIENT_USER_MARKET_PLACE_LISTING_EDIT_URL = (
  collectionPieceId: string | number
) => `/user/marketplace/edit/artwork/${collectionPieceId}`;
export const CLIENT_USER_MARKET_PLACE_LISTINGS_BY_STATUS_URL = (
  status: string
) => `/user/marketplace/listings/status/${status}`;
export const CLIENT_USER_MARKET_PLACE_ADD_ARTIST_URL = `/user/marketplace/add/artists`;
export const CLIENT_USER_MARKET_PLACE_ADD_ARTIST_ARTWORK_URL = (id: number) =>
  `/user/marketplace/add/artists/${id}`;
export const CLIENT_USER_MARKET_PLACE_ADD_REVIEW_URL = (
  artistId: number,
  variantId: number
) => `/user/marketplace/add/artists/${artistId}/artworks/${variantId}`;
export const CLIENT_ADD_VARIANT_REQUEST_REVIEW_URL = `/user/collection/add/request`;
export const CLIENT_ADD_COLLECTION_PIECE_REVIEW_URL = (
  artistId: number,
  variantId: number
) => `/user/collection/add/artists/${artistId}/artworks/${variantId}`;
export const CLIENT_OWNED_ARTIST_COLLECTION_URL = (urlHandle: string) =>
  `/user/collection/owned/${urlHandle}`;
export const CLIENT_ARTIST_PROFILE_URL = (urlHandle: string) =>
  `/artists/${urlHandle}`;
export const CLIENT_ARTIST_FEATURED_ARTWORKS_URL = (urlHandle: string) =>
  `/artists/${urlHandle}/artworks/featured`;
export const CLIENT_ARTIST_SHOWS_URL = (urlHandle: string) =>
  `/artists/${urlHandle}/artworks/shows`;
export const CLIENT_SHOWS_BY_NAME_URL = (name: string) => `/shows/${name}`;
export const CLIENT_ARTIST_SHOW_ARTWORKS_URL = (
  urlHandle: string,
  showName: string
) => `/artists/${urlHandle}/artworks/shows/${showName}`;
export const CLIENT_ARTIST_ARTWORKS_URL = (urlHandle: string) =>
  `/artists/${urlHandle}/artworks`;
export const CLIENT_ARTIST_LINKS_URL = (urlHandle: string) =>
  `/artists/${urlHandle}/links`;
export const CLIENT_ARTIST_WAITLIST_URL = (urlHandle: string) =>
  `/artists/${urlHandle}/waitlist`;
export const CLIENT_ADMIN_TRENDING_VIEW_URL = '/user/admin/trending';
export const CLIENT_ADMIN_WAITLIST_VIEW_URL = (url_handle: string) =>
  `/user/admin/waitlist/${url_handle}`;
export const CLIENT_ADMIN_USER_PROFILE_URL = (userId: string) =>
  `/user/admin/users/${userId}/profile`;

export const CMS_AUTH_MPO_SELLER_SHIPPING_URL =
  '/api/market-place-orders/auth/shipping';
export const CMS_AUTH_MPO_SOLD_URL = '/api/market-place-orders/auth/sold';
export const CMS_AUTH_MPO_PURCHASED_URL =
  '/api/market-place-orders/auth/purchased';
export const CMS_AUTH_MPL_URL = '/api/market-place-listings/auth';
export const CMS_PUBLIC_MPL_URL = '/api/market-place-listings/pub';

export const CMS_MPL_CHECKOUT_SESSION_URL =
  '/api/market-place-listings/auth/checkout';
export const CMS_PUB_MPL_FIND_BY_HANDLE = (handle: string) =>
  `/api/market-place-listings/pub/profile/${handle}`;

export const CMS_AUTH_CREATE_UMP_URL = '/api/user-market-places/auth/create';
export const CMS_AUTH_UMP_STRIPE_LOGIN_URL =
  '/api/user-market-places/auth/stripe/login';
export const CMS_AUTH_UMP_STRIPE_SETUP_COMPLETE_URL =
  '/api/user-market-places/auth/stripe/setup/complete';
export const CMS_AUTH_UMP_FIND_ME_URL = '/api/user-market-places/auth/find/me';

export const SHOW_NAMES_LE = 'limited editions';
export const SHOW_NAMES_OTHER = 'other';

export const userRoleNames = {
  artist: 'Artist',
  artDealer: 'ArtDealer',
  authenticated: 'Authenticated',
  gallery: 'Gallery',
  admin: 'Admin',
};

export const defaultImageMarqueeCarouselImages = [
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619103437/691_9aaf1ed6b7.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619105857/1692_285e71afb2.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619123510/5185_d5e8813945.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1637863257/Family_Black_758a972da2.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619110730/2821_7841b29a8c.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619104427/1047_565b6bfed1.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619104593/1123_8fa9b0d31a.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619103651/788_73f69d0f0a.png',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619103510/725_fc57074594.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619118746/3454_b1536194c9.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619105990/1743_0872afa73b.jpg',
  'https://res.cloudinary.com/hmkqf8kua/image/upload/v1637863239/Crystalized_Charmander_d26fcb64e0.jpg',
];
