import { ReactNode } from 'react';

export default function AttributeBox({
  children,
  my,
  mx = 'mr-0.8',
  py = 'py-0.4',
  px = 'px-0.8',
  background = 'bg-grey-100',
  color = 'text-black-100',
  font = 'text-xs',
}: {
  children: ReactNode;
  my?: string;
  py?: string;
  mx?: string;
  px?: string;
  background?: string;
  color?: string;
  font?: string;
}) {
  return (
    <div className={`${my} ${mx} ${py} ${px} ${background} ${color} ${font}`}>
      {children}
    </div>
  );
}
