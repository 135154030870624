export default function CtaText({
  font = 'text-s',
  margin = 'mr-0.8',
  textTransform = 'uppercase',
  children,
}: {
  font?: string;
  margin?: string;
  textTransform?: string;
  children: React.ReactNode;
}) {
  return (
    <span className={`${font} ${margin} ${textTransform}`}>{children}</span>
  );
}
